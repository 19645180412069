import {ICustomFile} from '../../../models/CustomFile';
import {IBaseSmartDevice, IConfigurationPropertyValue} from '../../../models/SmartDevice';

export const enum BessModeType {
  PeakShaving = 'PEAKSHAVING',
  Optimizer = 'OPTIMIZER',
  Imbalance = 'IMBALANCE'
}

export interface BessHealthLimit {
  upperHealthStatus: number | undefined;
  lowerHealthStatus: number | undefined;
}

export interface BessTreshold {
  upperHealthStatus: number | undefined;
  lowerHealthStatus: number | undefined;
  surplusReserve?: number | undefined;
  peakShavingSafety?: number | undefined;
}

export interface BessGeoLocation {
  latitude: number | undefined;
  longitude: number | undefined;
}

export const enum BessRate {
  FixedRate = 'FIXED_RATE',
  DynamicRate = 'DYNAMIC_RATE',
  CustomRate = 'CUSTOM_RATE'
}

export interface BessContact {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface BessCustomFile extends ICustomFile {
  timestamp: Date;
  ownerId: number | string;
}

export interface BessLiveData {
  capacity: number;
  mode: string;
  stateOfHealth: number;
  cycleCount: number;
  stateOfCharge: number;
  batteryPower: number;
  gridPower: number;
  productionPower: number;
}

export interface BessHistoricData {
  batteryChargeSolar: number;
  batteryChargeGrid: number;
  batteryDischargeLoad: number;
  batteryDischargeGrid: number;
  gridImportLoad: number;
  gridImportBattery: number;
  exportBatteryGrid: number;
  exportSolarGrid: number;
}

export interface BessLogbookData {
  timestamp: Date;
  event: string;
  currentStateOfCharge: number;
  requestedPower: number;
  setPointPower: number;
  setPointMode: string;
  deliveredPower: number;
}

export interface BessUsageConfigurationData {
  localProductionAvailable: boolean;
  modes: BessModeType[] | [] | undefined;
  treshold: BessTreshold;
}

export interface BessSpecificationData {
  serialNumber: string | undefined;
  geographicLocation: BessGeoLocation;
  maxCapacity: number | null | undefined;
  maxDischargeSpeed: number | null | undefined;
  maxChargeSpeed: number | null | undefined;
  maxStateOfCharge: number | null | undefined;
  healthLimits: BessHealthLimit;
  maxCurrent: number | null | undefined;
  maxPeakPower: number | null | undefined;
  capacityMaximumPower: number | null | undefined;
  minStateOfCharge: number | null | undefined;
  gridFee: number | null | undefined;
  rate: BessRate;
  customRate?: number | null | undefined;
  fixedTariff: number | null | undefined;
  multiplicator?: number | null | undefined;
  taxMultiplicator?: number | null | undefined;
}

export interface BessAuditTrailData {
  timestamp: Date;
  who: string;
  what: string;
}

export interface BessSLAData {
  contacts: BessContact[];
  files: BessCustomFile[];
}

// propertyLabels
export const bessModi = 'c.s.s.bess.mode';
export const bessCapacity = 'c.s.s.bess.capacity';
export const bessMaxChargeSpeed = 'c.s.s.bess.max.charge.speed';
export const bessMaxDischargeSpeed = 'c.s.s.bess.max.discharge.speed';
export const bessMaxStateOfCharge = 'c.s.s.bess.max.state.of.charge';
export const bessMaxSurplusReserveStateOfCharge = 'c.s.s.bess.max.surplus.reserve.state.of.charge';
export const bessMinStateOfCharge = 'c.s.s.bess.min.state.of.charge';
export const bessMinPeakShavingStateOfCharge = 'c.s.s.bess.min.peak.shaving.state.of.charge';

export function getSmartDeviceConfigurationPropertyValue(
  smartDevice: IBaseSmartDevice,
  specName: string
): string | number | string[] | undefined {
  if (!smartDevice || !specName) return;
  const property = smartDevice.configurationProperties?.find(property => property.spec.name === specName);
  if (property) {
    const values = property.values.map(value => value.String || value.BigDecimal);
    if (values) {
      if (specName === 'c.s.s.bess.mode') {
        // Array of strings to be handled separately
        const modeValues = values as string[];
        return values as string[];
      } else {
        // Other specs are single number or string values
        const specValue = values[0];
        if (specValue) {
          return specValue;
        } else {
          return undefined;
        }
      }
    }
  }
  return undefined;
}

export function getSmartDeviceConfigurationPropertyValues(
  smartDevice: IBaseSmartDevice,
  propertyName: string
): IConfigurationPropertyValue[] | undefined {
  const property = smartDevice.configurationProperties.find(property => property.spec.name === propertyName);
  return property && property.values;
}

export type BessData = {
  live: BessLiveData;
  historic: BessHistoricData;
  logbook: BessLogbookData;
  usageConfiguration: BessUsageConfigurationData;
  specification: BessSpecificationData;
  auditTrail: BessAuditTrailData;
  sla: BessSLAData;
};

export const DEFAULT_BESS_DATA_STATE = {
  live: {
    capacity: 62.5, // capacity equals StateOfCharge
    mode: BessModeType.PeakShaving,
    stateOfHealth: 99.7,
    cycleCount: 50,
    stateOfCharge: 62.5, // stateOfCharge equals capacity
    batteryPower: -400,
    gridPower: -500,
    productionPower: 100
  },
  historic: {
    batteryChargeSolar: 0,
    batteryChargeGrid: 0,
    batteryDischargeLoad: 0,
    batteryDischargeGrid: 0,
    gridImportLoad: 0,
    gridImportBattery: 0,
    exportBatteryGrid: 0,
    exportSolarGrid: 0
  },
  logbook: {
    timestamp: new Date(),
    event: '',
    currentStateOfCharge: 0,
    requestedPower: 0,
    setPointPower: 0,
    setPointMode: '',
    deliveredPower: 0
  },
  specification: {
    serialNumber: '',
    geographicLocation: {
      latitude: 0.0,
      longitude: 0.0
    },
    maxCapacity: 0,
    maxDischargeSpeed: 0,
    maxChargeSpeed: 0,
    maxStateOfCharge: 0,
    healthLimits: {
      upperHealthStatus: 0,
      lowerHealthStatus: 0
    },
    maxCurrent: 0,
    maxPeakPower: 0,
    capacityMaximumPower: 0,
    minStateOfCharge: 0,
    gridFee: 0,
    rate: BessRate.FixedRate,
    customRate: 0,
    fixedTariff: 0.1,
    multiplicator: 2,
    taxMultiplicator: 0
  },
  usageConfiguration: {
    localProductionAvailable: false,
    modes: [],
    treshold: {
      upperHealthStatus: 95, // Note: Can not be higher than 95
      lowerHealthStatus: 5, // Note: Can not be lower than 5
      surplusReserve: 75,
      peakShavingSafety: 25
    }
  },
  auditTrail: {
    timestamp: new Date(),
    who: '',
    what: ''
  },
  sla: {
    contacts: [],
    files: []
  }
};
